import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Image} from 'antd';
import {render} from 'react-dom';
import {SRLWrapper} from 'simple-react-lightbox'
import SimpleReactLightbox from 'simple-react-lightbox'
import Gallery from 'react-grid-gallery';

function importAll(r) {
    let images = [];
    r.keys().map((item, index) => {
        !item.endsWith('th.jpg') ?
            images.push({
                src: r(item),
                th: r(r.keys()[index - 1])
            }) : null
    });
    return images;
}

import placeholder from "../assets/images/placeholder.jpg"

const images = importAll(require.context('../assets/images/gallery/20210829/', false, /\.(png|jpe?g|svg)$/));

const ContactPage = () => {

    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Galleria | ${intl.formatMessage({id: "title"})}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-gallery">
                <div className="container text-center pt-4">
                    <h1>Galleria</h1>
                    <h5>Foto dell'evento: Domenica 29 agosto 2021</h5>
                </div>
                <section className="pt-4 px-0">
                    <div className="container px-0 m-auto text-center">
                        <SimpleReactLightbox className="px-0">
                            <SRLWrapper options={{
                                settings: {
                                    downloadedFileName: "WuerthOpen2021",
                                },
                                thumbnails: {
                                    showThumbnails: false
                                }
                            }}>
                                {images.map(img => {
                                    return (
                                        <a href={img.src} title="Wuerth Open 2021" key={img.src}>
                                            <img
                                                src={img.th}
                                                alt="Wuerth Open 2021"
                                                className="m-1 galleryimg">
                                            </img>
                                        </a>
                                    )
                                })}
                            </SRLWrapper>
                        </SimpleReactLightbox>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default ContactPage
